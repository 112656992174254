@import '~texkit/dist/styles/variables';

.delete-contact-modal {
  &-button {
    margin-left: $margin * 2;
  }

  &-card {
    border-bottom: 1px solid $color-gray-lighter;
    padding-bottom: $padding;

    @media #{$mq-min-sm} {
      align-items: flex-start;
    }
  }

  &-content {
    max-width: 400px;
    padding-top: $padding * 2;
  }

  &-footer {
    margin-top: $margin * 4;

    @media #{$mq-max-xs} {
      display: flex;
      flex-direction: column-reverse;
      button {
        margin: ($margin / 2) 0px;
      }
    }
  }

  &-header {
    max-width: 600px;
  }

  .delete-contact-modal-button {
    color: $color-white;
    background-color: $color-red-dark;
    border: 2px solid $color-red-dark;

    &:hover {
      color: $color-white;
      background-color: darken($color-red-dark, 15%);
      border-color: darken($color-red-dark, 15%);
    }
  } 
}
