@import '~texkit/dist/styles/variables';

.dps-donations-modal {
  .modal-title,
  .modal-description {
    margin: 0 0 16px 0 !important;

    @media #{$mq-min-md} {
      padding: 0px 110px;
    }
  }

  .modal-button {
    min-width: 80%;
    margin-top: ($margin * 2);

    @media #{$mq-min-sm} {
      min-width: 136px;
    }
  }
}
