@import '~texkit/dist/styles/variables';

.under_18 {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    width: 100%;
    padding: 24px 40px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid $color-gray;
    margin-top: 5px;
}

.under_18_nav {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    border-radius: 4px;
    border: 2px solid #DCDEE1;
    padding: 22px 24px;
    color: $color-black;
    margin-top: 10px;
}

.under_18_nav .text-button {
    margin-top: 48px;
    text-decoration: underline;
    color: $color-blue;
}

.text-button {
    text-decoration: underline;
    color: $color-blue;

    &:hover {
        text-decoration: none;
    }
}