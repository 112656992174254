@import '~texkit/dist/styles/variables';

.progress-bar {
  position: relative;
  height: 2px;
  background-color: $color-gray-lighter;
  width: 100%;

  &-indicator {
    position: absolute;
    display: block;
    height: 100%;
    top: 0;
    left: 0;
    background: $color-blue;
    transition: $transition-modal;
  }
}
