.reverify-speedbump-modal {
    max-width: 960px;
    padding: 0;
  
    .modal-body {
      font-size: 20px;       
    }
    
    .modal-content {        
        font-weight: 400;
        text-align: left;
        line-height: 28px;        
    }    
  }  
   