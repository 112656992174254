@import '~texkit/dist/styles/variables';

.hamburger {
  position: relative;

  &,
  &::before,
  &::after {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 100px;
    background-color: $color-white;
    transition: $transition-slow;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    left: 0;
  }

  &::before {
    bottom: 7px;
  }
  &::after {
    top: 7px;
  }

  &.isActive {
    background-color: transparent;
    transform: translate3d(0, -3px, 0) rotate(90deg);

    &::before {
      transform: rotate(-45deg) translate3d(-3px, 7px, 0);
    }
    &::after {
      transform: rotate(45deg) translate3d(-3px, -7px, 0);
    }
  }
}
