@import '~texkit/dist/styles/variables';

.audit-verification-modal {
  .desktop-img {
    padding: 50px;
    @media #{$mq-max-sm} {
      display: none;
    }
  }
  .mobile-img {
    padding: ($padding * 2) 50px;
    @media #{$mq-min-md} {
      display: none;
    }
  }
  .audit_label {
    display: flex;
  }
  .verification-alert {
    text-align: left;
  }
  .modal-title {    
    @media #{$mq-max-xs} {  
      font-weight: 700;
      margin-top: 0;
      font-size: $font-size-xs * 2;
    }
  }  
  .form-actions {
    @media #{$mq-max-xs} {
      margin: ($margin * 2) auto;
    }
  }
  .modal-body {
    padding: 0;
    @media #{$mq-max-xs} {
      padding: ($padding * 4) ($padding * 2);
    }
  }
}

.audit-verification-modal-ios {
  @media only screen and (max-width: 780px) {
    overflow-y: scroll;
    height: 90%;
    padding-bottom: 66px;
    -webkit-overflow-scrolling: touch;
  }
}
