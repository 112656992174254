@import '~texkit/dist/styles/variables';

.mismatch-service-modal {
  .modal-title,
  .modal-content {
    max-width: 624px;
  }

  .button-icon.modal-close {
      display: none;
  }

  .lead {
    margin-bottom: $margin * 4;
  }

  &-list {
    margin: 0 auto;
  }

  .summary-card {
    border-radius: 0;
    border-top: 1px solid $color-gray-light;

    margin: 0;

    // &:last-of-type {
    //   border-bottom: 1px solid $color-gray-light;
    // }
  }

  .license-plate-icon,
  .vehicle-details  {
      max-width: calc(50% - 15px);
      float: left;

      .summary-card-item {
       width: 100%;
       flex: auto; 
       margin-left: 35px;

        &:first-child {
            margin-top: 18px;
        }
      }
  }

  .service-icon-license-plate .text-container .text{
    
    font-size: 40px;
    padding-top: 32px;
  }
  
  .ButtonPrimary {
    background-color: transparent;
    border: 2px solid $color-blue;
    color: $color-blue;
  
    &:hover {
      color: $color-white;
    background-color: $color-blue;
    border: 2px solid $color-blue;
    }
  }
  .ButtonDanger {
    color: $color-white;
    background-color: $color-red-dark;
    border: 2px solid $color-red-dark;

    &:hover {
      color: $color-white;
      background-color: darken($color-red-dark, 15%);
      border-color: darken($color-red-dark, 15%);
    }
  } 
  
}