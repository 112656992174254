@import '~texkit/dist/styles/variables';

.input-code.input-wrapper:not(.input-checkbox):not(.input-radio) {
  margin: 0 auto;
  padding: 0;
  max-width: 264px;
  text-align: center;

  &.hasLabel:not(.input-label-position-above) .input {
    padding: 0 0 0 0.5em;
  }

  .input {
    padding: 0 0 0 0.5em;
    font-size: $font-size-h2;
    line-height: $line-height-header;
    letter-spacing: 0.5em;
    text-align: inherit;

    & + .input-label {
      left: 0;
      width: 100%;
      color: $color-gray;
      transition: none;
    }

    &[value] + .input-label,
    &[value='']:focus + .input-label {
      opacity: 0;
    }

    & + .input-label,
    &[value=''] + .input-label {
      margin-top: 0;
      opacity: 1;
    }
  }

  &.hasError {
    .input {
      color: $color-red-dark;

      & + .input-label {
        color: $color-gray;
      }
    }
  }
}
