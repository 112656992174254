@import '~texkit/dist/styles/variables';

.alert-container {
  margin-bottom: 0;
  ul {
    margin: 0;
  }

  text-align: left;  
}
