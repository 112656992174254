@import '~texkit/dist/styles/variables';

.dps-verify-address-modal {
  &-label {
    color: $color-gray;
    flex: 1 1;
    font-size: $font-size-md;
    text-align: left;
  }

  &-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input-radio {
    flex: 3 3;
    margin: 0;

    .input-wrapper-field {
      align-items: center;
    }

    .input {
      top: -4px;
    }
  }

  .modal-content {
    align-items: center;
    color: $color-black;
    max-width: 568px;
    text-align: center;
  }

  hr {
    align-content: center;
    border-top: 1px solid #c2c6cc;
    width: 100%;
    font-weight: 200;
  }
}

