@import '~texkit/dist/styles/variables';

.logo {
  display: inline-block;
  width: 165px;
  height: 32px;
  background-color: transparent;

  svg,
  path {
    color: inherit;
    fill: currentColor;
  }
}
