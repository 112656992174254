@import '~texkit/dist/styles/variables';
@font-face {
  font-family: 'Saira Extra Condensed';
  src: url('../../../assets/fonts/static/SairaExtraCondensed-SemiBold.ttf');
}


.service-icon 
{
    display: block;
    width: 100%;
    height: auto;
  

  &-license-plate {

    .text-container { 
      background-image: url('../../../assets/service-icons/license-plate.png');
      background-repeat:no-repeat;
      background-size: contain;
      height: 158px;
      max-width: 250px;
      text-align: center;
      position: inherit;
    }

    .text {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1.5px;
      font-size: 50px;
      color: #21294E;
      padding-top: 33px;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-transform: scaleY(1.2);
      transform: scaleY(1.2);
    }

    .modalText {
      font-family: 'Saira Extra Condensed';
      letter-spacing: 1.5px;
      font-size: 50px;
      color: #21294E;
      padding-top: 33px;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-transform: scaleY(1.2);
      transform: scaleY(1.2);
      transition: .3s ease-in-out;

      @media only screen and (max-width: 800px) {
        font-size: 30px;
      }
  
      @media only screen and (max-width: $breakpoint-sm) {
        font-size: 50px;
      }
  
      @media only screen and (max-width: 551px) {
        font-size: 36px;
      }
    }
  }
}