@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.summary-card {
  margin: (16px * 1.5) 0;
  text-align: left;

  .card-body {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
  }

  &-graphic {
    height: auto;
    margin: (16px / 2) (16px * 4) (16px * 3/2) 0;
    width: 88px;

    @media #{$mq-min-md} {
      margin-top: 0;
      margin-left: -8px;
    }

    .service-icon svg {
      box-shadow: none;
    }
  }

  &-list {
    @extend %flex-row-wrap;
    flex: 1 1 auto;
    width: 100%;
  }

  &-item {
    @extend %flex-item;
    width: 100%;
    max-width: 100%;
    padding: ($padding * 3/4) ($padding / 2);
    flex: 1 1 auto;

    @media #{$mq-min-sm} {
      flex: 1;
      min-width: 50%;
    }

    &-full-width {
      width: 100%;
      max-width: 100%;
    }
  }

  dd {
    margin-top: 16px * 0.25;
    font-size: $font-size-base;
    padding-right: $padding / 2;
    word-break: break-word;
    hyphens: auto;
  }

  .input-wrapper-field {
    max-width: 384px;
  }

  .toggle-group {
    position: static;
    width: 100%;
  }

  .toggle-group-switch {
    top: 16px * 0.5;
    right: 16px * 0.5;

    @media #{$mq-min-sm} {
      top: 16px * 0.75;
      right: 16px * 0.75;
    }
  }

  &.side-by-side {
    .summary-card-list {
      flex: 2 1 auto;
      width: auto;
    }
    .summary-card-item {
      min-width: 100%;
    }
    .summary-card-graphic {
      flex: 1 1 auto;
      width: auto;
      svg {
        max-width: 130px;
      }
    }
  }
}
