@import '~texkit/dist/styles/variables';

.input-radio {
  $input-size: 24px;

  border: none;
  border-radius: 2px;
  margin: ($margin * 1.5) 0;

  .input-wrapper-field {
    display: flex;
    align-items: flex-start;
  }

  .input-label {
    position: relative;
    padding: 2px 0 0 ($padding + $input-size);
    font-size: $font-size-base;
    line-height: 1.5;
    cursor: pointer;

    &::before {
      position: absolute;
      content: '';
      flex: 0 0 auto;
      top: 1px;
      left: 0;
      width: $input-size;
      height: $input-size;
      margin: 0;
      padding: 0;
      max-width: $input-size;
      border-radius: $input-size;
      appearance: none;
      background-color: transparent;
      border: solid 2px $color-gray-light;
      cursor: pointer;
      transition: $transition-fast;
    }

    &::after {
      position: absolute;
      content: '';
      top: 13px;
      left: 12px;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
      border-radius: 50%;
      background: $color-blue;
      opacity: 0;
      transform: scale(0.5, 0.5);
      visibility: hidden;
      transition: $transition-fast;
    }
  }

  .input {
    position: absolute;
    opacity: 0;
    height: $input-size;
    width: $input-size;

    &:focus ~ .input-label {
      &::before {
        outline: none;
        box-shadow: 0 0 1px 3px #2d94f3;
      }
    }

    &:checked ~ .input-label {
      font-weight: 700;

      &::before {
        border-color: $color-blue;
      }

      &::after {
        opacity: 1;
        transform: scale(1, 1);
        visibility: visible;
      }
    }
  }
}

.input-radio-inverse {
  color: $color-white;

  .input-label {
    &::before {
      border-color: rgba($color-white, 0.6);
    }

    &::after {
      background: $color-white;
    }
  }

  .input:checked ~ .input-label {
    &::before {
      border-color: $color-white;
    }
  }
}
