@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.nav-dropdown {
  position: relative;
  display: inline-block;

  &.isOpen {
    z-index: $z-index-dropdown;
  }
  &-menu-nav {
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    width: 630px;
    background-color: $color-white;
  }
  &-menu {
    list-style: none;
    padding: $padding 0;
    min-width: 170px;
    color: $color-black;

    &-item {
      position: relative;
      padding: 0 0 0 16px;
      box-sizing: border-box;

      &-action {
        @extend %button-reset;
        display: block;
        width: 100%;
        padding: ($padding * 0.75) ($padding * 1.5);
        line-height: 1.25;
        text-align: left;
        white-space: nowrap;
        color: $color-blue;
        box-sizing: border-box;

        &,
        &:hover {
          text-decoration: none;
        }

        &:focus {
          background-color: transparent;
        }
        &:hover {
          background-color: transparent;
          font-weight: bold;
        }
      }
      &-label {
        display: block;
        padding: 12px 26px 12px 42px;
      }
    }
    &-services {
      background-color: #f5f6ff;
      color: $color-gray;
      margin: 8px;
      border-radius: 4px;
    }
    &-service-item {
      padding: 16px 16px 16px 24px;
    }
    &-service-title {
      color: $color-blue;
      text-decoration: none;
      &:hover {
        font-weight: bold;
      }
      p {
        margin: 0;
        color: $color-gray;
      }
    }
    &-services-list {
      margin: 8px 8px 8px 16px;
      padding: 0;
    }
  }

  &-menu-position-bottom-right &-menu {
    transform-origin: 0 100%;
    //top: 100%;
    //right: 0;
    margin-top: 0;

    &:before {
      transform-origin: 100% 0;
    }
  }

  &.hasCheck {
    .icon-check {
      display: block;
    }
  }

  &-current-selected {
    font-weight: bold;
  }
}

// Transitions

// Off State
.dropdown-menu-enter,
.dropdown-menu-exit.dropdown-menu-exit-active {
  opacity: 0;
  overflow: hidden;
  transition: $transition-dropdown;

  &::before {
    transform: scale(0.75);
    border-radius: $border-radius * 4;
    transition: $transition-dropdown;
  }

  .dropdown-menu-item {
    transition: $transition-dropdown;
  }

  .dropdown-menu-position-bottom-right & {
    transform: translate(0, -24px);

    .dropdown-menu-item {
      transform: translate(20px, -20px);
    }
  }
}

// On State
.dropdown-menu-enter.dropdown-menu-enter-active,
.dropdown-menu-exit {
  opacity: 1;
  overflow: visible;
  transform: translate(0, 0);
  transition: $transition-dropdown;

  &::before {
    transform: scale(1);
    border-radius: $border-radius;
    transition: $transition-dropdown;
  }

  .dropdown-menu-item {
    transform: translate(0, 0);
    transition: $transition-dropdown;
  }
}

// While Leaving & Entering
.dropdown-menu-enter,
.dropdown-menu-enter.dropdown-menu-enter-active,
.dropdown-menu-exit,
.dropdown-menu-exit.dropdown-menu-exit-active {
  pointer-events: none;

  .dropdown-menu-item-action {
    transition: none;
  }
}

.menu-list {
  width: 50%;
  margin: 8px;
}
.sub-item {
  margin: 8px 0 0 26px;
}

.language-switcher-menu-item {
  display: flex;
  padding-left: 64px;
  .icon-check {
    margin-left: auto;
  }
}
.current-star.icon-lone-star {
  color: $color-blue;
  margin: 0 8px 2px -22px;
  width: 14px;
  height: 14px;
}
