@charset "UTF-8";
.accordion_section {
  border-bottom: 2px solid #dcdee1; }
  .accordion_section .accordion {
    background-color: #fff;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0;
    cursor: pointer; }
    .accordion_section .accordion .accordion_title {
      margin: 24px 0;
      font-size: 18px;
      color: #47525d;
      font-weight: 600;
      position: relative; }
      .accordion_section .accordion .accordion_title:after {
        content: '+';
        position: absolute;
        right: -20px;
        top: -10px;
        font-size: 36px;
        font-weight: 200; }
    .accordion_section .accordion:focus {
      outline: none; }
  .accordion_section .accordion_content {
    display: none;
    text-align: left; }
  .accordion_section.active {
    padding-bottom: 24px; }
    .accordion_section.active .accordion .accordion_title {
      color: #304ffe; }
      .accordion_section.active .accordion .accordion_title:after {
        content: '−'; }
    .accordion_section.active .accordion_content {
      display: block; }
      .accordion_section.active .accordion_content .accordion_text {
        margin: 0; }
