@import '~texkit/dist/styles/variables';

.credit-card-icon {
  position: relative;
  display: inline-flex;
  width: 1.5em;
  height: 1em;
  line-height: 1;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  svg {
    width: 100%;
    height: 100%;
  }
}
