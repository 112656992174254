@import '~texkit/dist/styles/variables';

.form-actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: ($margin * 3) auto;

  @media #{$mq-min-sm} {
    margin-top: ($margin * 4);
    margin-bottom: ($margin * 4);
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &-wrapper {
    margin-left: -$margin;
    margin-right: -$margin;
  }

  &-item {
    flex-grow: 0;
    margin: 0;
    padding: ($padding * 0.25) $padding;
    width: 100%;
    text-align: center;

    @media #{$mq-min-sm} {
      flex-basis: auto;
      width: auto;
    }

    .button,
    a {
      width: 100%;
      //max-width: 240px;

      &.text-button {
        width: auto;
      }
    }

    a.button{
      max-width: none;
      overflow: unset;
    }

    .text-button {
      height: 48px;
      line-height: 48px;
      padding: 0 $padding;
    }

    &-full-width {
      width: 100%;
    }
  }

  &-align-center {
    @media #{$mq-min-sm} {
      justify-content: center;
    }
  }

  &-align-right {
    @media #{$mq-min-sm} {
      justify-content: flex-start;
    }
  }

  &-direction-column {
    flex-direction: column;
  }

 
}
