@import '~texkit/dist/styles/variables';

.modal-header {
  height: auto;
  width: auto;
  color: #304ffe;
}

.modal-description {
  margin: 0 0 16px 0 !important;
  color: #47525d;
  text-align: center;
  display: contents;

  @media #{$mq-min-md} {
    font-size: 20px;
    line-height: 28px;
  }

  div {
    margin-bottom: 20px;
  }
}

.modal-close-button {
  margin-top: 42px;
  height: 56px;
}