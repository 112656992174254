@import '~texkit/dist/styles/variables';

.section {
  padding: ($padding * 1.5) 0;

  @media #{$mq-min-md} {
    padding: ($padding * 2) 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-view-all {
    color: $color-gray;
    text-transform: uppercase;

    &:hover {
      color: $color-blue;
    }
  }

  &-title {
    color: $color-gray;
    font-size: $font-size-lg;
    margin: 0 0 $margin;

    @media #{$mq-min-sm} {
      font-size: $font-size-xl;
      margin-bottom: $margin * 1.5;
    }
  }
}
