@import '~texkit/dist/styles/variables';

#UnlinkServiceModal .unlink-service-modal {
    
  @media (max-width: 624px) { 
    .modal-title {
        padding: 0px 20px;
        font-size: 25px;
        text-align: center;
    }
  }
  
  .lead {
    margin-bottom: $margin * 2;
    text-align: center;
    font-weight: 300;    
  }

  &-list {
    margin: 0 auto;
  }

  .summary-card {
    border-radius: 0;
    border-top: 1px solid $color-gray-light;
    margin: 0;

    &:last-of-type {
      border-bottom: 1px solid $color-gray-light;
    }
  }
  
  .license-plate-icon,
  .vehicle-details {
    max-width: calc(50% - 15px);
    float: left;

    .summary-card-item {
      width: 100%;
      flex: auto;

      &:first-child {
        margin-top: 18px;
      }
      
    }
  }
  .service-icon-license-plate .text {
    padding-top: 20px;
  }

  .service-icon-license-plate .modalText {
    padding-top: 20px;
  }

  @media (max-width: 624px) { 
    .service-icon-license-plate .text-container .text {
        font-size: 20px;
        padding: 0px 1px;
        padding-top: 5px;
        font-size: 39px;
    }
  }
  @media (max-width: 624px) { 
      .flex-row-wrap {
        flex-wrap: nowrap;
        flex-direction: column;
    }
  }
  @media (max-width: 624px) { 
    .license-plate-icon {
      padding: 0;
      height: 75px;
      width: 345px;
    }
    .vehicle-details {
      white-space:nowrap;
      margin: 0;
      max-width: calc(50% - 20px);
      padding-top: 30px;
      
      .summary-card-item {
        width: 100%;
        flex: auto;
        color: $color-black;

        &:first-child {
          margin-top: 18px;          
        }
      }
    }
  }

  @media (max-width: 624px) { 
    .service-icon .text {
        height: 12%;
        text-align: center;
    }
  }

  .ButtonPrimary {
    background-color: transparent;
    border: 2px solid $color-blue;
    color: $color-blue;

    &:hover {
      color: $color-white;
      background-color: $color-blue;
      border: 2px solid $color-blue;
    }
  }
  .ButtonDanger {
    color: $color-white;
    background-color: $color-red-dark;
    border: 2px solid $color-red-dark;

    &:hover {
      color: $color-white;
      background-color: darken($color-red-dark, 15%);
      border-color: darken($color-red-dark, 15%);
    }
  }  
}

.dlr-modal.unlink-service-modal {
  @media (max-width: 624px) {
    
    .flex-row-wrap {
      align-items: center;
      text-align: center;
    }
    .license-plate-icon {
      height: auto;
      max-width: 60%;
      margin-top: 20px;
    }
    .vehicle-details {
      padding-top: 0;
      max-width: 80%;
    }
    .summary-card {
      padding: 0;

      dd {
        padding: 0;
      }
    }
    .summary-card:last-of-type {
      border-bottom: none;
    }
  }
}

#UnlinkServiceModal .unlink-service-modal-ios {
  @media only screen and (max-width: 780px) {
    overflow-y: scroll;
    height: 90%;
    padding-bottom: 66px;
    -webkit-overflow-scrolling: touch;
  }
}