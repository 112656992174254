@import '~texkit/dist/styles/variables';

.input-group-credit-card-payment {
  .flex-row-wrap {
    margin: (-$margin * 0.75);
  }

  .flex-item {
    padding: $padding * 0.75;
    flex: 0;
  }

  .input-credit-card-expiration,
  .input-credit-card-cvv {
    width: 145px;
  }

  &-input-wrapper {
    width: 100%;
  }
}
