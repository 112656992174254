@import '~texkit/dist/styles/variables';

.container {
  display: block;
  margin: 0 auto;
  padding: 0 ($padding * 1.5);
  width: 100%;
  max-width: $max-width-container;

  @media #{$mq-min-sm} {
    padding: 0 ($padding * 2);
  }
  @media #{$mq-min-md} {
    padding: 0 ($padding * 3);
  }
  @media #{$mq-min-lg} {
    padding: 0 ($padding * 4);
  }
}
