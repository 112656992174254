.otp-sent-content {
    strong {
        font-weight: 600;
    }
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;    
    
    p:first-of-type {
        margin-top: 0;
    }
}