@import '~texkit/dist/styles/variables';

.input-group-security-questions {
  .input-group + .input-group {
    margin-top: $margin * 1.5;

    @media #{$mq-min-sm} {
      margin-top: $margin * 2;
    }
  }

  .input-security-answer {
    margin-top: -$margin;

    @media #{$mq-min-sm} {
      margin-top: -$margin * 1.5;
    }
  }
}
