@import '~texkit/dist/styles/variables';

.payment-form-error-modal {
    padding: 0;
    .modal-content {
        color: $color-black;
        font-size: $font-size-lg;
        font-weight: 400;        
    }
}
