.unlist {
  list-style: none;
  margin: 0;
  padding: 0; }
  .unlist li {
    margin: 0;
    padding: 0; }

/**
  Example:
  `<div class="p-3 pb-0 m-2"></div>`

  Output:

                      8px
       +----------------------------------+
       |                                  |
       |              16px                |
       |        +---------------+         |
       |        |               |         |
  8px  |  16px  |               |   16px  |  8px
       |        |               |         |
       |        +---------------+         |
       |              0px                 |
       +----------------------------------+
                      8px
*/
.p-0 {
  padding: 0; }

.p-1 {
  padding: 4px; }

.p-2 {
  padding: 8px; }

.p-3 {
  padding: 16px; }

.p-4 {
  padding: 24px; }

.p-5 {
  padding: 32px; }

.pt-0 {
  padding-top: 0; }

.pt-1 {
  padding-top: 4px; }

.pt-2 {
  padding-top: 8px; }

.pt-3 {
  padding-top: 16px; }

.pt-4 {
  padding-top: 24px; }

.pt-5 {
  padding-top: 32px; }

.pr-0 {
  padding-right: 0; }

.pr-1 {
  padding-right: 4px; }

.pr-2 {
  padding-right: 8px; }

.pr-3 {
  padding-right: 16px; }

.pr-4 {
  padding-right: 24px; }

.pr-5 {
  padding-right: 32px; }

.pb-0 {
  padding-bottom: 0; }

.pb-1 {
  padding-bottom: 4px; }

.pb-2 {
  padding-bottom: 8px; }

.pb-3 {
  padding-bottom: 16px; }

.pb-4 {
  padding-bottom: 24px; }

.pb-5 {
  padding-bottom: 32px; }

.pl-0 {
  padding-left: 0; }

.pl-1 {
  padding-left: 4px; }

.pl-2 {
  padding-left: 8px; }

.pl-3 {
  padding-left: 16px; }

.pl-4 {
  padding-left: 24px; }

.pl-5 {
  padding-left: 32px; }

.m-0 {
  margin: 0; }

.m-1 {
  margin: 4px; }

.m-2 {
  margin: 8px; }

.m-3 {
  margin: 16px; }

.m-4 {
  margin: 24px; }

.m-5 {
  margin: 32px; }

.mt-0 {
  margin-top: 0; }

.mt-1 {
  margin-top: 4px; }

.mt-2 {
  margin-top: 8px; }

.mt-3 {
  margin-top: 16px; }

.mt-4 {
  margin-top: 24px; }

.mt-5 {
  margin-top: 32px; }

.mr-0 {
  margin-right: 0; }

.mr-1 {
  margin-right: 4px; }

.mr-2 {
  margin-right: 8px; }

.mr-3 {
  margin-right: 16px; }

.mr-4 {
  margin-right: 24px; }

.mr-5 {
  margin-right: 32px; }

.mb-0 {
  margin-bottom: 0; }

.mb-1 {
  margin-bottom: 4px; }

.mb-2 {
  margin-bottom: 8px; }

.mb-3 {
  margin-bottom: 16px; }

.mb-4 {
  margin-bottom: 24px; }

.mb-5 {
  margin-bottom: 32px; }

.ml-0 {
  margin-left: 0; }

.ml-1 {
  margin-left: 4px; }

.ml-2 {
  margin-left: 8px; }

.ml-3 {
  margin-left: 16px; }

.ml-4 {
  margin-left: 24px; }

.ml-5 {
  margin-left: 32px; }

.text-decoration-none {
  text-decoration: none; }

.tabbed {
  width: 100%; }
  .tabbed nav {
    margin-left: -16px;
    margin-right: -16px; }
  .tabbed nav button {
    background: none;
    border: 0 solid transparent;
    border-radius: 0;
    font-weight: bold;
    color: #6c7782;
    font-size: 1em;
    line-height: 1.5;
    margin: 0 16px;
    padding: 24px 0;
    cursor: pointer;
    border-bottom: solid transparent 2px;
    transition: border-bottom-color 0.35s ease-in-out, color 0.35s ease-in-out; }
    .tabbed nav button.active {
      color: #304ffe;
      border-bottom-color: #304ffe; }
  .tabbed__nav-list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .tabbed__nav-list > li {
      display: inline;
      margin: 0;
      padding: 0; }
  .tabbed__content {
    padding: 32px 0; }
