@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

@keyframes loading {
  0% {
    color: $color-blue;
  }
  50% {
    color: $color-blue-dark;
  }
  100% {
    color: $color-blue;
  }
}

.loading {
  height: 136px;
  width: 136px;

  &,
  path {
    color: inherit;
    fill: currentColor;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 100%;
    animation: loading 2s infinite;

    &:not(.inline) {
      top: $navbar-height;
      height: calc(100vh - #{$navbar-height});
      position: fixed;
    }
  }
}
