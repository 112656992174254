@import '~texkit/dist/styles/variables';

.tdlr-link-service-confirm {
  h1 {
    margin: 0;
  }
  p {
    margin: 16px 0 0 0;
  }
  &-summary {
    margin-bottom: 64px;
  }
  .summary-card {
    margin: 32px 0;
  }
}
