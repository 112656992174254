@import '~texkit/dist/styles/variables';

.delete-payment-account-modal {
  &-button {
    margin-left: $margin * 2;
  }

  &-card {
    border-bottom: 1px solid $color-gray-lighter;
    padding-bottom: $padding;

    @media #{$mq-min-sm} {
      align-items: flex-start;
    }

    .payment-card-info {
      align-items: flex-start;
      flex-direction: column;

      @media #{$mq-min-sm} {
        text-align: left;
        margin-left: $margin * 4;
      }
    }
  }

  &-content {
    max-width: 400px;
    padding-top: $padding * 2;

    .credit-card-icon {
      font-size: 68px;
    }
  }

  &-footer {
    margin-top: $margin * 4;

    @media #{$mq-max-xs} {
      display: flex;
      flex-direction: column-reverse;
      button {
        margin: ($margin / 2) 0px;
      }
    }
  }

  &-header {
    max-width: 600px;
  }
}
