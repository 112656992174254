.link-dl-id-modal .modal-title,
.modal-description {
  margin: 0 0 16px 0 !important;
}

.modal-close-button {
  margin-top: 42px;
}

.link-dl-id-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-close-button {
      max-width: 140px;
    }
  }
}
