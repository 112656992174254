@import '~texkit/dist/styles/variables';

.loading-dots {
  position: relative;
  width: 56px;
  height: 16px;
  margin: 16px 0;

  &-container {
    min-height: 80px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  > span {
    position: absolute;
    display: block;
    height: $margin;
    width: $margin;
    margin: 0 ($margin / 4);
    margin-left: -16px;
    background-color: $color-blue;
    border-radius: 50% !important;
    overflow: hidden;
    animation: 2s infinite;

    &:nth-child(1) {
      animation-name: loading-dot-one;
    }
    &:nth-child(2) {
      animation-name: loading-dot-two;
    }
    &:nth-child(3) {
      animation-name: loading-dot-three;
    }
    &:nth-child(4) {
      animation-name: loading-dot-four;
    }
  }
}

@keyframes loading-dot-one {
  0% {
    left: 0;
  }
  25% {
    left: 50%;
  }
  50% {
    left: 100%;
    transform: scale(1);
    opacity: 1;
  }
  75% {
    left: 100%;
    transform: scale(0);
    opacity: 0;
  }
  76% {
    left: 0;
  }
  100% {
    left: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes loading-dot-two {
  0% {
    left: 50%;
  }
  25% {
    left: 100%;
    transform: scale(1);
    opacity: 1;
  }
  50% {
    left: 100%;
    transform: scale(0);
    opacity: 0;
  }
  51% {
    left: 0;
  }
  75% {
    left: 0;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    left: 50%;
  }
}

@keyframes loading-dot-three {
  0% {
    left: 100%;
    transform: scale(1);
    opacity: 1;
  }
  25% {
    left: 100%;
    transform: scale(0);
    opacity: 0;
  }
  26% {
    left: 0;
  }
  50% {
    left: 0;
    transform: scale(1);
    opacity: 1;
  }
  75% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}

@keyframes loading-dot-four {
  0% {
    left: 100%;
    transform: scale(0);
    opacity: 0;
  }
  1% {
    left: 0;
  }
  25% {
    left: 0;
    transform: scale(1);
    opacity: 1;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 100%;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    left: 100%;
    transform: scale(0);
    opacity: 0;
  }
}
