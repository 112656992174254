@import '~texkit/dist/styles/variables';

.input-group-emergency-address {
  .input-unit {
    &.input-wrapper.input-label-position-above {
      margin-top: -$margin;

      @media #{$mq-min-sm} {
        margin-top: -$margin * 1.5;
      }
    }
  }
}
.input-street {
  margin-top: 0px;
}

.input-w-ext + .input-wrapper {
  margin-top: 0 !important;
}

.input-w-ext {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
    
  .input-wrapper:first-child .input-wrapper-field {
      margin-right: $margin;
  }

  .input-ext {
    margin-top: 0 !important;
    margin-bottom: $margin * 1.5 !important;
      @media #{$mq-min-sm} {
        margin-bottom: $margin * 2 !important;
      }
  }
}

.input-phone-ext {
  .input-phone {
    width: 70%;
  }
  .input-ext {
    width: 30%;
  }
}

.input-zipFour-ext {
  .input-zip-code {
    width: auto;
  }
  .input-zip-code label,
  .input-ext {
    max-width: 160px;
  }
}