@import '~texkit/dist/styles/variables';

.navbar {
  margin: 0 auto;
  color: $color-white;

  &-left,
  &-right {
    z-index: 2;
    display: flex;
    align-items: center;
  }

  &-container,
  &-container.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $navbar-height;
  }
}
