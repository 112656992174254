@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.footer {
  display: block;
  font-size: $font-size-sm;
  color: $color-white;
  background: #20294f;
  padding: ($padding * 2) 0;

  .language-switcher {
    margin-left: -16px;
  }

  @media #{$mq-min-lg} {
    padding: 0;
  }

  .container.dps {
    align-items: flex-start;
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    @media #{$mq-max-sm} {
      flex-direction: column;
      align-items: stretch;
    }

    @media #{$mq-min-lg} {
      min-height: $footer-height;
    }
  }

  &-nav.dps {
    margin-bottom: $margin;
  }

  &-nav {
    display: flex;
    padding-right: $padding;
    justify-content: flex-start;
    margin-left: $margin * 6;

    @media #{$mq-min-lg} {
      margin-top: $margin * 2;
    }

    .dps {
      flex-direction: column;
      align-items: flex-start;
      max-width: 304px;
    }

    @media #{$mq-max-sm} {
      margin-left: 0;
      margin-top: 0;
      flex-direction: column;
    }

    &-list {
      display: flex;
      padding: 0;
      margin-top: 0px;
      list-style: none;
      align-items: flex-start;

      @media #{$mq-max-sm} {
        margin: 0 0 0 0;
        display: block;
      }

      @media #{$mq-min-md} {
        justify-content: start;
        flex: auto;
      }

      &-item {
        margin: 0 $margin 0 0;
        text-align: left;
        display: flex;

        @media #{$mq-max-sm} {
          margin-bottom: $margin * 1.5;
        }

        a,
        button {
          text-align: left;
          padding: 0 ($padding * 1.25) ($padding) ($padding * 1.25);
          transition: $transition-fast;

          &,
          &:hover {
            color: $color-white;
            text-decoration: underline;
            background: none;
          }

          @media #{$mq-min-md} {
            &.language-switcher-toggle {
              &:hover {
                background-color: darken($color-blue, 15%);
              }
            }
          }

          @media #{$mq-max-sm} {
            padding: 0;
          }

          @media #{$mq-min-lg} {
            display: block;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &-logo-copyright {
    display: flex;
    margin-right: $margin * 8;

    @media #{$mq-max-sm} {
      margin-bottom: $margin * 2;
      margin-right: $margin;
      padding-left: $margin / 4;
    }
  }

  &-copyright {
    display: flex;
    flex-basis: max-content;

    @media #{$mq-max-sm} {
      order: 2;
      flex-direction: column;
      margin-bottom: 0;
    }

    @media #{$mq-min-md} {
      margin-bottom: $margin;
    }

    @media #{$mq-min-lg} {
      order: 0;
      margin-top: $margin;
      padding: $margin 0;
    }

    .state-of-texas-logo {
      width: 45px;
      height: 45px;
      margin-right: $margin;
    }
    .copyright-text {
      max-width: 240px;
      min-width: 120px;
    }
    img {
      max-width: initial;
    }
  }
}

.text-button {
  text-transform: none;
}