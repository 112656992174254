@import '~texkit/dist/styles/variables';

.vrr-timeout-warning { 
    .modal-body {
        padding: 0 ($padding * 3);
    }

    &-timer {
    font-weight: bold;
    color: $color-red;
  }  
}
