@import '~texkit/dist/styles/variables';

.edit-payment-account-modal {
  .payment-frame {
    height: 1460px;
    margin: 0 auto;

    @media #{$mq-min-sm} {
      height: 1180px;
    }
  }

  .iframe-error {
    margin-top: ( $margin * 3 );
    text-align: left;
  }
}
