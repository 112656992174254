@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.navbar-menu {
  z-index: 1;
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  transition: $transition-slow;

  @media #{$mq-min-md} {
    display: flex;
  }

  &-item {
    a {
      display: block;
      height: $navbar-height;
      line-height: $navbar-height;
      padding: 0 25px;
      color: $color-white;
      font-weight: 700;
      transition: $transition-fast;

      &:hover {
        background-color: darken($color-blue-dark, 8%);
      }
    }
  }

  body.searchActive & {
    opacity: 0;
    visibility: hidden;
    transform: translate(-75px, 0);
    pointer-events: none;
  }
}
