@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.language-switcher {
  position: relative;
  display: inline-block;
  font-size: $font-size-md;

  &-toggle {
    @extend %button-reset;

    width: 112px;
    display: inline-block;
    color: $color-white;
    padding: 0 $padding ($padding / 2 - 2px);
    font-size: $font-size-sm;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 19px;
    cursor: pointer;
    border-radius: 20px;
    text-transform: uppercase;
    transition: $transition-fast;
    text-decoration: none !important;
    text-align: center;

    .icon-chevron-down {
      font-size: 24px;
      position: relative;
      top: -2px;
      margin-left: 4px;
    }

    &:hover {
      background-color: darken($color-blue, 8%);
    }
  }

  .dropdown-menu {
    min-width: 120px;
    padding: ($padding / 2) 0;

    &-item {
      &-action {
        color: $color-black;
        text-decoration: none;
        padding: ($padding / 2) ($padding * 2) ($padding / 2) $padding;

        &:hover {
          color: $color-black;
          text-decoration: none;
        }
      }

      .icon-check {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -9px;
        color: $color-blue;
        font-size: $font-size-lg;
        opacity: 0;
      }

      &.isSelected {
        .icon-check {
          opacity: 1;
        }
      }
    }
  }
}