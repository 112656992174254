@import '~texkit/dist/styles/variables';

.dont-have-id-modal {
  max-width: 905px;  
  padding-bottom: 48px;

  &-title {
    font-size: 32px;
    color: $color-blue;
    margin: 0;
  }

  .modal-content {
    color: $color-black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 0 32px;
  }  
  
  &-btns {
    justify-content: center;
    display: flex; 
    gap: 16px;
    margin-top: 64px;
  } 

  @media only screen and #{$mq-max-sm} {
    .modal-content {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
      padding-left: initial;
      padding-right: initial;
    }

    .dont-have-id-modal-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }

    .dont-have-id-modal-btns .button {
      width: 100%;
      margin-right: 24px;
      margin-left: 24px;
    }
    
  }
}
