@import '~texkit/dist/styles/colors';

.linking-guidance-modal {
  .toggle-card-display, 
  .modal-close-button {
    margin-top: 48px;
  }

  .modal-body {
    padding: 0;
  }

  p, ul {
    color: #000;
    text-align: left;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    .modal-title {
      font-size: 24px;
    }
    .modal-body {
      padding: 64px 24px;
    }

    p {
      margin-bottom: 24px;
    }

    .modal-close-button {
      width: 100%;
      max-width: 279px;
    }
  }

  .modal-close {
    color: $color-blue;
  }
}