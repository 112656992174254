@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.navbar-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $navbar-height;
  line-height: $navbar-height;
  transition: $transition-slow;

  @media #{$mq-min-md} {
    margin-right: ($margin * 1.25);
  }

  &-toggle {
    @extend %button-reset;
    position: relative;
    font-size: 24px;
    width: 48px;
    height: 48px;
    line-height: 0;
    color: $color-white;
    border-radius: 100px;
    transition: $transition-medium;

    &:hover {
      background: darken($color-blue-dark, 8%);
    }
  }
}
