@import '~texkit/dist/styles/variables';

.server-error-modal {
  .modal-graphic {
    max-width: 300px;
  }  

  .service-down-description {
    color: $color-black;
    font-size: $font-size-lg;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
