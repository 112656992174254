@import '~texkit/dist/styles/variables';

.payment-frame {
  width: 360px;
  max-width: 100%;
  height: 1590px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
