@import '~texkit/dist/styles/variables';

.input-textarea {
  &.input-wrapper:not(.input-checkbox):not(.input-radio) {
    .input {
      height: 112px;
      padding: ($padding) ($padding * 1.5 - 2px);
    }
  }
}
