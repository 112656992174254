@import '~texkit/dist/styles/variables';

.grow-transition {
  transform-origin: 50% 25%;

  &-enter {
    opacity: 0.01;
    transform: scale(0.95);

    &#{&}-active {
      opacity: 1;
      transform: scale(1);
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &-exit {
    position: absolute;
    top: 0;
    opacity: 1;
    transform: scale(1);

    &#{&}-active {
      opacity: 0.01;
      transform: scale(0.95);
    }
  }
}
