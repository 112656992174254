@import '~texkit/dist/styles/variables';

.stepper-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  margin-left: auto;
  margin-right: auto;

  &-step {
    width: 8px;
    height: 8px;
    background: $color-gray-light;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    transform: scale(1, 1);
    transition: $transition-very-slow;

    &.isActive {
      transform: scale(2, 2);
      background: $color-yellow-dark;
    }
  }
}
