@import '~texkit/dist/styles/variables';

.verify-address-modal {
  @media #{$mq-min-sm} {
    top: 20%;

    .modal-body {
      padding: 0;
    }
  }

  .modal-close {
    color: $color-blue;
  }

  .modal-title {
    color: $color-blue;
    font-size: $font-size-base * 2;
    margin-bottom: $spacing * 3;
  }

  &-label {
    color: $color-black;
    font-size: $font-size-md;
    text-align: left;
    display: flex;

  }
  
  &-label>div{
    width: 40%;
  }

  .address-label {
    width: 30%;
  }

  &-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing * 2;
  }

  .input-radio {
    flex: 3 3;
    margin: 0;

    .input-wrapper-field {
      align-items: center;
    }

    .input {
      top: -4px;
    }
  }

  .modal-content {
    align-items: center;
    color: $color-black;
    max-width: 700px;
    text-align: center;
  } 

  hr {
    align-content: center;
    border-top: 1px solid #c2c6cc;
    width: 100%;
    font-weight: 200;
  }  

  .address-truncation-msg{
    text-align: left;
    padding-top: 30px;
  }

  @media #{$mq-max-xs} {
    .modal-title {
      font-size: $font-size-lg;
    }
    &-label {
      display: flex;
      flex-wrap: wrap;
    }
    &-label>div{
      width: 100%;
    }

    .address-label {
      margin-bottom: $spacing;
      width: 100%
    }
  }
}
