.forgot-username-id-modal {
  .modal-body {
    font-size: 20px;
    padding: 32px;
  }
  .modal-heading {
    margin-bottom: -80px;
    color: #47525d !important;
  }
  .tx-modal-body {
    height: 278px !important;
    overflow: hidden;
  }
}
