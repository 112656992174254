@import '~texkit/dist/styles/variables';

.input-group-address-with-country {
  .input-unit {
    &.input-wrapper.input-label-position-above {
      margin-top: -$margin;

      @media #{$mq-min-sm} {
        margin-top: -$margin * 1.5;
      }
    }
  }
}
