@import '~texkit/dist/styles/variables';

.navbar-avatar {
  background: none;
  border: none;
  cursor: pointer;
  height: 100%;
}

.navbar-account-dropdown {
  position: relative;
  display: none;
  width: 48px;
  height: $navbar-height;
  align-items: center;
  justify-content: center;

  @media #{$mq-min-md} {
    display: flex;

    &-menu {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: -4px;
      padding: ($padding * 0.75) 0;
      min-width: 150px;
      color: $color-black;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      overflow: hidden;
      transform: translate(0, -24px);
      transform-origin: 0 100%;
      transition: $transition-dropdown;

      &-item {
        position: relative;
        line-height: 1;
        transform: translate(20px, -20px);
        transition: $transition-dropdown;

        a {
          display: block;
          padding: ($padding * 0.75) ($padding * 1.5);
          text-align: right;
          white-space: nowrap;
          color: $color-black;
          transition: none;
        }
      }
    }
  }

  &.isOpen {
    .navbar-account-dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
      pointer-events: auto;
      overflow: visible;

      &-item {
        transform: translate(0, 0);

        a {
          transition: $transition-fast;

          &:hover {
            background-color: darken($color-white, 8%);
            cursor: pointer;
          }
        }
      }

      &::before {
        transform: scale(1, 1);
        border-radius: $border-radius;
      }
    }
  }
}
