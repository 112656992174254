@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.navbar-search {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transition: $transition-slow;
  height: $navbar-height;

  @media screen and (min-width: 650px) {
    position: relative;
    height: 48px;
    width: 48px;
  }

  @media #{$mq-min-md} {
    margin-right: ($margin * 0.5);
  }

  &-toggle,
  &-close {
    @extend %button-reset;
    top: 0;
    left: 0;
    font-size: 24px;
    width: 48px;
    height: 48px;
    line-height: 0;
    color: $color-white;
    transition: $transition-slow;
    border-radius: 100px;
  }

  &-toggle {
    z-index: 1;
    position: relative;

    @media screen and (min-width: 650px) {
      z-index: 3;
      position: absolute;
    }

    &:hover {
      background-color: darken($color-blue-dark, 8%);
    }
  }

  &-close {
    z-index: 1;
    position: absolute;
  }

  &-close {
    display: none;
    left: auto;
    right: 0;
    color: $color-gray;

    .icon {
      @media screen and (min-width: 650px) {
        font-size: $font-size-lg;
      }
    }

    &:hover {
      color: $color-black;
    }
  }

  &-form {
    position: relative;
    width: 100%;

    &-wrapper {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 $padding;
      width: 66.66%;
      height: $navbar-height;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition-slow;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;

      @media screen and (min-width: 650px) {
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  }

  &-input {
    border-radius: 100px;
    width: 100%;
    transition: $transition-slow;
    background-color: darken($color-blue-dark, 8%);

    &.input-wrapper:not(.input-checkbox):not(.input-radio):not(.input-select) {
      margin-bottom: 0;

      .input {
        height: 48px;
        padding: ($padding / 2) ($padding * 2.5) ($padding / 2) ($padding * 3);

        border-radius: 100px;
        border-color: transparent;
        opacity: 0;
        transition: $transition-slow;
        background-color: transparent;
        font-size: $font-size-base;

        &-icon-search {
          color: $color-black;
          top: -2px;
          left: 0;
          right: auto;
          height: 48px;
          width: 48px;
          line-height: 48px;
          text-align: center;
          transform: none;
        }
      }
    }
  }

  &:hover {
    body:not(.searchActive) .navbar-search-input {
      background: darken($color-blue-dark, 8%);
    }
  }

  &-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 16px;
    right: 16px;

    @media screen and (min-width: 650px) {
      width: 100%;
      left: 0;
      right: auto;
      margin-top: ($margin / 2);
    }

    .dropdown-menu {
      width: 100%;

      &-item {
        &-action {
          white-space: normal;
        }
      }
    }

    &-no-results {
      .dropdown-menu-item-action {
        cursor: default;

        &:hover {
          background-color: $color-white;
          cursor: default;
        }
      }
    }
  }

  body.searchActive & {
    @media screen and (min-width: 650px) {
      width: 300px;
    }

    &-form {
      &-wrapper {
        width: 100%;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }

    &-toggle {
      opacity: 0;
    }

    &-close {
      display: block;
    }

    &-dropdown {
      display: block;
    }

    &-input.input-wrapper:not(.input-checkbox):not(.input-radio):not(.input-select) {
      background-color: $color-white;

      .input {
        opacity: 1;
      }
    }
  }
}
