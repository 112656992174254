@import '~texkit/dist/styles/variables';

.modalOptInModalBody {
  div {
    text-align: left !important;
    p {
      margin: 0;
      padding: 24px 0;
    }

    ul {
      margin: 0 0 0 16px;
    }

    li {
      margin: 0;
      padding: 0;
    }
  }
}
