@import '~texkit/dist/styles/variables';

.toggle-group {
  position: relative;

  &-switch {
    position: absolute;
    top: -($margin / 2);
    right: -($margin / 2);
    padding: ($padding / 2);
    color: $color-blue;
    font-size: $font-size-sm;
    text-decoration: none;

    &-add-icon {
      color: $color-green-light;
      height: 32px;
      width: 32px;
    }

    @media #{$mq-min-sm} {
      font-size: $font-size-md;
    }
  }

  &-edit {
    .toggle-group-switch {
      top: -($margin * 3.75);
    }
  }
}
