@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

#DPSFAQModal,
#DPS_Renewal_FAQModal,
#DPS_REPLACEMENT_FAQModal,
#DPS_EMERGENCY_FAQModal {
  overflow-y: auto;
}

.faq-modal {
  height: auto;
}

.faq-modal .modal-body {
  padding: ($padding * 4) 12px ($padding * 4) 0;

  @media #{$mq-min-sm} {
    padding: 72px;
  }
}

.faq-modal .modal-content-wrapper {
  height: calc(100vh - 128px);
  overflow-y: auto;

  @media #{$mq-min-sm} {
    height: auto;
  }
}

.faq-modal .modal-title-general {
  color: #304FFE;
}

.faq-modal .modal-title
.faq-modal .modal-description {
  display: block;
  text-align: center;
  padding: 0px 10px 0 24px;
  margin: 0 0 16px 0 !important;

  @media #{$mq-min-sm} {
    padding: 0px 50px;
  }
}

.faq-modal .modal-description {
  @media #{$mq-min-sm} {
    margin-bottom: 20px !important;
  }
}

.faq-modal .modal-subHeading {
  text-align: left;
  font-size: 24px;
  font-weight: normal;
}

.faq-modal .modal-inner-body {
  padding: 0 ($padding * 2);
  text-align: left;

  @media #{$mq-min-sm} {
    height: 370px;
    overflow-y: auto;
    padding: ($padding * 2) ($padding * 3);
    margin: ($margin * 1) 0;
  }
}

.faq-modal .modal-content p {
  color: #47525d;
  font-weight: bold;
}

.faq-modal .modal-button {
  display: none;

  @media #{$mq-min-sm} {
    display: inline-block;
    margin-top: ($margin * 1);
  }
}

.faq-modal .modal-button-mobile {
  padding: 0;
  min-width: auto;
  width: 50px;
  height: 50px;
  background-color: #f1f3ff;
  border: none;
  position: fixed;
  right: 10px;
  bottom: 14px;

  @media #{$mq-min-sm} {
    display: none;
  }
}

.faq-modal .modal-button-mobile .icon {
  margin: -10px 0 0 0;
}

.faq-modal .modal-button-mobile .icon svg,
.faq-modal .modal-button-mobile .icon path {
  fill: #000000;
}
