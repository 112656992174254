@import '~texkit/dist/styles/variables';

.address-invalid-modal {
  @media #{$mq-min-sm} {
    top: 20%;

    .modal-body {
      padding: 0;
    }
  }

  .modal-close {
    color: $color-blue;
  }

  .modal-title {
    color: $color-blue;
    font-size: $font-size-base * 2;
    margin-bottom: $spacing
  }

  .modal-description {
    margin-bottom: $spacing;
    font-size: $font-size-base;
  }

  h4 {
    margin-top: $spacing;
    margin-bottom: $spacing * 3;
    font-size: $spacing;
    color: $color-black
  }

  &-label>div{
    width: 40%;
  }

  @media #{$mq-max-xs} {
    .modal-title {
      font-size: $font-size-xl;
    }
}
}
