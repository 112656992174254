@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.session-warning-modal {
  .modal-title {
    margin-bottom: 0;
  }

  &-timer {
    font-weight: bold;
    color: $color-blue;
    margin-bottom: $margin / 2;
  }

  .form-actions {
    margin-top: $margin * 1.5;

    @media #{$mq-min-sm} {
      margin-top: $margin * 2;
    }
  }
}
