@import '~texkit/dist/styles/variables';

.modalDMVSpecialCharBody {
  div {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 20px;

    @media #{$mq-max-sm} {
      flex-direction: column;
      text-align: center;
    }

    section:first-of-type {
      margin-right: 32px;

      @media #{$mq-max-sm} {
        margin-right: 0px;
      }
    }

    p {
      margin: 0;
      padding: 0 0 16px 0;
    }
  }
}
