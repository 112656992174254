@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/colors';

.server-error-modal {
  .modal-body {
    padding: 36px;
  }

  .modal-graphic {
    max-width: 300px;
    margin-bottom: $margin * 4;
  }

  .service-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-left: $margin * .5;
    transform: translateY($spacing * .5);
  }

  .service-icon svg {
    fill: $color-blue
  }
}
