@import '~texkit/dist/styles/variables';

.step-up-modal {
    max-width: 854px;
    padding: 0;

    &-title {
        font-size: 32px;
        color: $color-blue;
        margin: 0;
    }

    &-message {        
        margin-top: 16px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        &-bold{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 48px;
        }
    }

    &-btns {
        justify-content: center;
        display: flex; 
        gap: 16px;
    }

    @media #{$mq-max-xs} {  
        &-btns {
            flex-direction: column;
        }
        &-btns button:first-child {
           order: 2
        }
    }
}

@media #{$mq-min-sm} {  
    .step-up-modal > div {
        padding: 64px 87px;
    }
}