@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/placeholders';

.mobile-nav {
  z-index: 20;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  top: 0;
  width: 100%;
  font-weight: 700;
  overflow: auto;
  opacity: 1;
  visibility: visible;
  transition: $transition-very-slow;

  @media #{$mq-min-md} {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &__top-level {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background-color: $color-blue;
    box-shadow: 0 0 6px 0 #022f66;
  }

  a,
  button {
    text-decoration: none;
    background: transparent;
  }

  button {
    @extend %button-reset;
    padding: 0;
  }

  &-container {
    position: absolute;
    background-color: $color-white;
    height: 100%;
    opacity: 0;
    overflow: auto;
    transform: translate3d(-75px, 0, 0);
    transition: $transition-very-slow;
    padding-left: $padding * 1.5;
    padding-right: $padding * 1.5;
    padding-bottom: $padding * 2;
    padding-top: $padding * 4.5;

    @media #{$mq-min-sm} {
      padding-left: $padding * 6;
      padding-right: $padding * 6;
    }

    body.mobileNavActive & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &-primary-menu,
  &-services-menu,
  &-secondary-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: normal;
  }

  &-primary-menu {
    font-size: $font-size-base;
    line-height: $line-height-header;

    &-item {
      color: $color-blue;
      margin-top: ($margin * 2);
      padding-left: 38px;
      &:hover {
        font-weight: bold;
      }
    }
  }

  &-services-menu {
    background-color: #f5f6ff;
    margin-top: 32px;
    padding: 24px 0;
    &-item {
      &:not(:first-child) {
        margin-top: ($margin * 2);
      }
      padding: 0 30px 0 38px;
    }
  }

  &-secondary-menu {
    line-height: 40px;
    margin-top: ($margin * 3.5);
  }

  &-language-menu {
    margin-top: ($margin * 2);
    padding-left: 38px;
    button {
      color: $color-blue;
      margin-top: ($margin * 2);
      padding-left: 16px;
    }
  }

  &-background {
    position: relative;
    width: 100%;
    height: 152px;
    overflow: hidden;
    flex-shrink: 0;

    &-graphic {
      position: absolute;
      width: 100%;
      height: 152px;
      left: 50%;
      bottom: -8px;
      min-width: 897px;
      opacity: 0;
      transform: translate(-60%, 30px);
      transition: $transition-very-slow;

      body.mobileNavActive & {
        opacity: 0.7;
        transform: translate(-60%, 0);
      }

      @media #{$mq-min-sm} {
        transform: translate(-50%, 30px);

        body.mobileNavActive & {
          transform: translate(-50%, 0);
        }
      }
    }
  }

  &-branding {
    color: $color-white;
    transition: $transition-very-slow;

    @media #{$mq-min-sm} {
      top: $padding * 2;
      left: $padding * 2;
    }

    body.mobileNavActive & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .toggle-button-override {
    top: 0;
  }

  &-toggle {
    z-index: $z-index-navbar + 1;
    transition: $transition-slow;

    body.searchActive & {
      @media screen and (max-width: 649px) {
        display: none;
      }
    }

    @media #{$mq-min-md} {
      display: none;
    }

    &-container,
    &-container.container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: $navbar-height;

      @media #{$mq-max-sm} {
        padding-right: $padding;
      }
    }

    &-button {
      @extend %button-reset;
      position: relative;
      display: flex;
      top: $navbar-height;
      justify-content: center;
      align-items: center;
      padding: ($padding / 2);
      height: 48px;
      width: 48px;
      font-size: 24px;
      line-height: 0;
      border-radius: 50%;
      transition: $transition-slow;
      background-color: $color-blue;

      &:hover {
        background-color: darken($color-blue, 8%);
      }

      @media #{$mq-min-sm} {
        transform: translate3d(0, 0, 0);

        body.mobileNavActive & {
          transform: translate3d(0, ($margin / 2), 0);
        }
      }
    }
  }
}
.current-selected {
  font-weight: bold;
}
