@import '~texkit/dist/styles/variables';

.deactivate-user-modal {
  .modal-body {
    padding: 50px;
  }

  .modal-title {
    color: $color-blue;
  }

  .text-danger {
    color: #D2082D;
  }
}
