@import '~texkit/dist/styles/variables';

.license-details-modal {
  .modal-graphic {
    max-width: 450px;
  }

  .toggle-card-display, 
  .modal-close-button {
    margin-top: 42px;
  }

  .description {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    color: #20294f;
  }
  
  .dl-number {
    margin-bottom: 12px;
  }

  .modal-graphic {
    width: 388px;
    
    .horizontal {
      width: 388px;
    }
  }

  @media only screen and #{$mq-max-sm} {
    .modal-body {
      padding-bottom: 104px;
    }

    .modal-title {
      font-size: 24px;
      line-height: 30px;
    }

    .modal-graphic {
      width: 100%;
    }

    .toggle-card-display {
      margin-top: 24px;
    }

    .button {
      width: 279px;
    }

    .modal-close-button {
      margin-top: 64px;
    }
    .text-button:hover {
      text-decoration: underline;
    }
  }
}
