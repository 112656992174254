@import '~texkit/dist/styles/variables';

.icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: $margin * 4;

    .icon-box {
        display: flex;
        width: 200px;
        min-width: 200px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        flex-shrink: 0;
        div {
            width: 164px;
        }
    }   
    
    .icon-image {       
        display: flex;
        width: 48px;
        height: 48px;      
        justify-content: center;
        align-items: center;
        padding: 4px 5px;  
        opacity: 0.5;              
    }
}
