.feeTotal {
  border-top: 1px solid #304ffe;
  border-bottom: 1px solid #304ffe;
  color: #304ffe;
  font-weight: 800;
  font-size: 22px;
  .value {
    text-align: right;
  }
}
.feeTotalWrapper {
  padding-top: 64px;
}
