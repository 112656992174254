@import '~texkit/dist/styles/variables';

.input-group-code {
  flex-flow: column;
  text-align: center;

  .input-code.input-wrapper:not(.input-radio):not(.input-checkbox) {
    margin: $margin auto 0;
  }

  &-resend-code {
    display: inline-block;
    margin: ($margin * 1.5) auto 0;
    padding: 0;
    line-height: 24px;

    .icon {
      margin-top: -4px;
      margin-left: ($margin * 0.75);
    }

    &-error {
      color: $color-red-dark;

      .icon {
        margin-top: -2px;
      }
    }

    .text-button {
      padding: 0;
      color: $color-blue;
      font-size: $font-size-base;
    }
  }
}
