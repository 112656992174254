@import '~texkit/dist/styles/variables';

// Requires MaxHeight to be set dynamically (see Drawer.js)
.drawer-transition {
  &-enter {
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &#{&}-active {
      overflow: hidden;
    }
  }

  &-exit {
    &#{&}-active {
      max-height: 0;
      transition: max-height 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      overflow: hidden;
    }
  }
}
