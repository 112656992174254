@import '~texkit/dist/styles/variables';

.slide-transition {
  transform-origin: 50% ($margin * 4);

  &-enter {
    opacity: 0.01;
    transform: translate3d(($margin * 6), 0, 0);

    &.reverse {
      transform: translate3d(($margin * -6), 0, 0);
    }

    &#{&}-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: $transition-modal;
    }
  }

  &-exit {
    position: absolute;
    top: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &.reverse {
      transform: translate3d(($margin * 6), 0, 0);
    }

    &#{&}-active {
      opacity: 0.01;
      transform: translate3d(($margin * -6), 0, 0);
    }
  }
}
