@import '~texkit/dist/styles/variables';

.address-two-missing-modal {
  padding: $spacing * 4;
  
  .form-actions {
    margin-top: $spacing * 3;
  }
  
  @media #{$mq-min-sm} {
    top: 20%;    

    .modal-body {
      padding: 0;
    }
  }

  .modal-close {
    color: $color-blue;
  }

  .modal-title {
    color: $color-blue;
    font-size: $font-size-base * 2;
    margin-bottom: $spacing
  }

  .modal-description {
    margin-bottom: $spacing;
    font-size: $font-size-base;
  }

  h4 {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 3;
    font-size: $spacing;
    color: $color-black
  }

  .address-line-2 {
    width: 54%;
    margin: auto;
  }

  &-label>div{
    width: 40%;
  }

  @media #{$mq-max-xs} {
    padding: 0;
    .modal-title {
      font-size: $font-size-lg;
    }
    .address-line-2 {
      width: 100%;
      margin: auto;
    }
}
}
