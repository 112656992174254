@import '~texkit/dist/styles/variables';

.input-group {
  &.isGrid {
    margin: -($margin / 4);
  }

  &-grid {
    display: flex;
    flex-wrap: wrap;

    > .input-wrapper,
    > .input-radio-group {
      padding: ($padding / 4);
      margin: 0;

      &.input-radio,
      &.input-checkbox {
        margin-left: 4px;
        margin-right: 4px;
      }

      &.hasHelpText {
        margin-bottom: $margin * 1.5;
      }
    }
  }
}
