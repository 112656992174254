@import '~texkit/dist/styles/variables';

.modal-header {
  height: auto;
  width: auto;
  color: #304ffe;
}

.real-id-page-modal {
  @media only screen and (max-width: 780px) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.real-id-page-modal-ios, .real-id-page-modal {
  .modal-header {
    @media #{$mq-max-xs} {  
      font-weight: 700;
      margin-top: 0;
      font-size: $font-size-xs * 2;
    }
  }

  .modal-close-button {
    @media #{$mq-max-xs} {      
      margin-top: $margin;
    }
  }

  .modal-body {
    padding: 0;
    @media #{$mq-max-xs} {
      padding: ($padding * 4) ($padding * 2);      
    }
  }    
}    

.real-id-page-modal-ios {
  @media only screen and (max-width: 780px) {
    overflow-y: scroll;
    height: 90%;
    padding-bottom: 66px;
    -webkit-overflow-scrolling: touch;
  }
}

.modal-description {
  margin: 0 0 16px 0 !important;
  color: #47525d;
  text-align: center;
  display: contents;

  @media #{$mq-min-md} {
    font-size: 20px;
    line-height: 28px;
  }
}

div {
  margin-bottom: 20px;
}

.modal-close-button {
  margin-top: 42px;
  height: 56px;
}
